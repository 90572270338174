<template>
  <div>
    <el-row>
      <div class="wraper" v-wechat-title="this.goodsinfo.name+ '-3A医药城'">
        <ShopHead :productName="productName"></ShopHead>
        <ActivityModuleGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo" :ActivityType="activityType"></ActivityModuleGalss>
        <ActivityModuleInfo
          class="info"
          v-if="renderFlag"
          :goodsinfo="goodsinfo"
          :ActivityType="activityType"
        ></ActivityModuleInfo>
      </div>
    </el-row>
    <el-row>
      <el-col :span="24" v-if="activityType==1">
        <DetailInfo
          class="detailInfo"
          v-if="renderFlag"
          :goodsinfo="goodsinfo"
        ></DetailInfo>
      </el-col>
      <el-col :span="24"  v-else>
        <!-- 限时购 -->
        <DetailInfo
          class="detailInfo"
          v-if="renderFlag"
          :goodsinfo="goodsinfo.productArray[0]"
        ></DetailInfo>

        <!-- <p class="setMealCon_header">
          <span>
            包含商品
          </span>
        </p>
         <div class="setMealCon_main">
           <comboItem :productType="productType" v-for="(item,index) in goodsinfo.productVOList" :key="index" :info="item"></comboItem>
         </div> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
// const comboItem=()=>import('components/index/comboItem.vue');
import {   TimeBuyDetail,OpenBookingDetail} from "api/activity.js";
// import Qs from 'qs'
const ActivityModuleGalss = () => import("components/product/ActivityModuleGalss.vue");
const ActivityModuleInfo = () => import("components/product/ActivityModuleInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
const DetailInfo = () => import("components/product/DetailInfo.vue");
export default {
  name: "ActivityModuleDetail",
   inject:['reload'],
  data() {
    return {
      productType:'setMeal',
      productName:'',
      renderFlag: false,
      goodsinfo: "",
      activityType:''
    };
  },
  components: {
    ActivityModuleGalss,
    ActivityModuleInfo,
    ShopHead,
    DetailInfo,
    // comboItem
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.productId": function() {
        this.reload();
    },
  },
  created() {
    this.activityType=Number(this.$route.query.ActivityType)
    this.getProductDetail();
  },
  methods: {
    getProductDetail() {
      if(this.activityType==1){
        this.productName="人气爆品"
          //  alert('预售' +this.activityType)
          const parmes ={
            promotionId:this.$route.query.promotionId
          };
            // 显示loading
          this.$loading({ fullscreen: true ,background:'#ffffff45'})
          //  console.log(parmes)
            OpenBookingDetail(parmes).then((res) => {
              // //console.log(res.data.data);
              if(res.data.code==400){
                this.$message.error(res.data.msg);
                let that=this
                setTimeout(function(){that.$router.push({name:"Home"})},500);
              }
              this.productName=res.data.data.name
              this.goodsinfo = res.data.data;
              this.renderFlag = true;
              // 关闭loading
                this.$loading().close();
            });
      }else{
        // alert(this.$route.query.id)
        // alert('超级秒杀')
         this.productName="超级秒杀"
           const parmes ={
            promotionId:this.$route.query.id
          };
            // 显示loading
          this.$loading({ fullscreen: true ,background:'#ffffff45'})
          TimeBuyDetail(parmes).then(res=>{
            this.goodsinfo = res.data.data;
             this.productName=res.data.data.name
             this.renderFlag = true;
             // 关闭loading
                this.$loading().close();
          })
      }
    
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.el_main {
  background: #fff;
}
.wraper {
  margin-top: 20px;
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 458px);
  }
  // .detailInfo {
  //   width: 1200px;
  // }
}
.setMealCon_header{
   font-weight: bold;
    height: 50px;
    background: #f6f6f6;
    span {
      background: #ff3333;
      color: #fff;
      font-size: 16px;
      display: inline-block;
      width: 120px;
      text-align: center;
      line-height: 50px;
    }
}
.setMealCon_main{
   box-sizing: content-box;
    padding:0;
    margin-top: 30px;
    margin-bottom: 30px;
    float:left;
    width:100% ;
}
</style>

